import React, { useState,useEffect } from "react";
import './style.css';
import { useNavigate } from "react-router-dom";
import { Environments, URLS, View } from "../constants/config";
import useFetch from "../hooks/useFetch";
import { User } from "../utils/tools";
import { InititalizeAnimation } from "../utils/initAnimations";
import { VERSION } from "../environments/version";
//import { VERSION } from 'environments/version';
import { Services } from "../services/index";

function LoginTool() {
  
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [defaults,setDefaults] = useState({uname:"",pass:"",school:""})
  const navigate = useNavigate()
  //const [schools] = useFetch(URLS.INTERNAL.SCHOOLS_LISTING)
  const [schools,setSchools] = useState([])
  
  const [view,setView] = useState("")

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass,schoolUuid,login_type } = document.forms[0];
let payload = {username:uname.value,password:pass.value,schoolUuid:schoolUuid.value}
  let url =URLS.INTERNAL.LOGIN
  if(login_type.value==="squid"){
    url = URLS.INTERNAL.SQUID_LOGIN
  }


  if (uname.value && pass.value) {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
      .then((response) => {
        if(response.ok){
          response.json().then((data)=>{
            //teacher_id.value
            let u = JSON.stringify({...data})
            let isTeacher=true
            if(data.roles instanceof Array && data.roles.length>0 && data.roles.indexOf("student")!==-1){
              u = JSON.stringify({...data})
              isTeacher=false
            }
            localStorage.setItem("user", u);
            setIsSubmitted(true);
            //do not require old animation
            // if(!isTeacher){
            //   InititalizeAnimation()
            // }
            let redirect_url = isTeacher?"/list-recordings":"/"
            if(redirect_url !== "/list-recordings"){
              const audioPlay = new Audio("/animation-assets/en/SFX_real_theme.mp3");
              audioPlay.play().catch((e) => {
                console.log(e, "audio play error")
              })
            }
            navigate(redirect_url);
          })
        }
        else {
          response.json().then((data)=>{
              setErrorMessages({ name: "uname", message: data?.message });
          })
        }
      },(error)=>{
        console.log("error",error)
      });

    }
    return
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <>
      <img src="sign-in-image.png" alt="" style={{ width: "200px" }} />
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Username </label>
            <input
              type="text"
              name="uname"
              defaultValue={defaults.uname}
              required
            />
            {renderErrorMessage("uname")}
          </div>
          <div className="input-container">
            <label>Password</label>
            <input
              type="password"
              name="pass"
              defaultValue={defaults.pass}
              required
            />
            {renderErrorMessage("password")}
          </div>
          <div className="input-container">
            <label>Login Type </label>
            <div style={{ display: "inline-block" }}>
              <input
              defaultChecked
                type="radio"
                name="login_type"
                value={"realreaders"}
              />
              <span style={{ marginRight: "3rem" }}>REAL Reader</span>{" "}
              <input type="radio" name="login_type" value={"squid"} />
              sQuid
            </div>
          </div>
          {schools ? (
            <div className="input-container">
              <label>School</label>
              <select
                required={true}
                id="schoolUuid"
                name="schoolUuid"
                className="form-control"
                defaultValue={defaults.school}
              >
                  <option key={"None"} value={""}>
                    {"Select School"}
                  </option>                
                {schools.map(({ schoolUuid, name }) => (
                  <option key={schoolUuid} value={schoolUuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="input-container" style={{ textAlign: "right" }}>
            <a href="/request-passcode" style={{textDecoration:"none"}}>Forgot Password?</a>
          </div>
          <div className="input-container" style={{ textAlign: "center" }}>
              Version: {VERSION}
          </div>

          <div className="button-container">
            <input
              type="submit"
              style={{
                background:
                  "linear-gradient(23deg, rgba(64, 8, 183, 0.20) 40.27%, rgba(208, 143, 44, 0.20) 410.01%), #43099E",
              }}
              value="Login"
            />
          </div>
        </form>
      </div>
    </>
  );

  const LoginForm = () => {
    return (
      <div className="app">
        <div className="login-form">
          <div className="title">Sign In</div>
          {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
        </div>
      </div>
    )
  }

  const NoPermission = () => {
    return (
      <div className="app">
        <div className="login-form">
          <div className="title">No Microphone Permission</div>
          <span>Allow Microphone and restart the browser</span>
        </div>
      </div>
    )
  }

  const SetDefaultSchool = () => {
    Services.School.listSchool().then(async (response) => {
      if (!typeof (await response) === false) {
        const data = await (await response).json();
        setSchools(Services.School.sortASC(data));
        //GardenCommunitySchoolUUID
        let defaultRadioSchoolUUID = "835fa462-81a1-4197-b573-f015f3e6391a";
        //Test School
        //defaultRadioSchoolUUID ="f0d3417a-317b-4f5b-b0d4-58b0cd4f848f";
        let defaultSchoolExists =
          data &&
          data.find((x) => {
            return x?.schoolUuid === defaultRadioSchoolUUID;
          })?.schoolUuid
            ? true
            : false;
        setDefaults({
          ...defaults,
          //school: defaultSchoolExists === true ? defaultRadioSchoolUUID : "",
          school: "",
        });

        //setting up dev. defaults
        const form = document?.forms[0];
        if (form && Environments.isDev && !defaults?.uname) {
          const defaults = {
            uname: User.default.username,
            pass: User.default.password,
            school: User.default.school,
          };
          setDefaults(defaults);
        }
      }
    });
  };

  useEffect(() => {
    let promise = navigator.mediaDevices.getUserMedia({ audio: true });
    promise
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setView(View.LoginScreen);
        SetDefaultSchool()
      })
      .catch(() => {
        setView(View.NoAudioPermission);
      });

  },[])

  return (
    <>
    {view === "" && ""}
    {view === View.NoAudioPermission && <NoPermission/>}
    {view === View.LoginScreen && <LoginForm />}
    </>
  )
}

export default LoginTool;